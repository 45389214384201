import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Container from 'components/grid/container';
import MaxWidth from 'components/grid/maxWidth';
import CategoryHero from 'components/heros/categoryHero';
import SignUpCta from 'components/ctas/signUpCta';
import WysiwygArea from 'components/content/wysiwygArea';

const ContentContainer = styled(Container)`
  border-bottom: 1px solid ${p => p.theme.greyLight};
`;

const HelpArticle = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        hero,
      },
      html,
    },
  } = data;

  return (
    <React.Fragment>
      <CategoryHero
        title={hero.title}
        subtitle={hero.subtitle}
        image={hero.image}
        dark="true"
      />

      <ContentContainer pt={[5, 5, 10]} pb={[10, 10, `100px`]}>
        <MaxWidth>
          <WysiwygArea>
            {html}
          </WysiwygArea>
        </MaxWidth>
      </ContentContainer>

      <SignUpCta />
    </React.Fragment>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      ...LayoutFragment
      ...CategoryHeroFragment
      html
    }
  }
`;

HelpArticle.propTypes = {
  data: PropTypes.object.isRequired,
};

export default HelpArticle;
